import React from 'react'
import withRouter from '../../routers/withRouter'
import Blink from './blink'
import './largeUserMenu.css'

const DEFAULT_CARET = require('../../images/CaretUpBlue@2x.png')

const LargeUserMenuButton = ({
  label = 'label',
  subLabel,
  subLabelStyle,
  icon: Icon,
  hideBorder,
  onClick,
  to,
  whenFollowed,
  match,
  activeLabelStyle,
  inactiveLabelStyle,
  buttonStyle,
  iconContainerStyle,
  testID,
  displayCaret,
  caretIcon,
  caretStyle,
  iconStyle,
  labelStyle,
  ...rest
}) => (
  <Blink to={match.path !== to && to} onClick={onClick} whenFollowed={whenFollowed} data-testid={testID}>
    <div className="oneclick-large-user_menu-button" style={buttonStyle} {...rest}>
      <div style={iconContainerStyle}>{Icon && <Icon style={iconStyle} />}</div>
      <div
        style={{
          border: hideBorder && 'none',
          ...(match.path === to && activeLabelStyle),
          ...(match.path !== to && inactiveLabelStyle)
        }}>
        <p style={{ ...labelStyle, marginBottom: subLabel ? 3 : 1 }}>{label}</p>
        {subLabel && <p style={subLabelStyle}>{subLabel}</p>}
      </div>
      {displayCaret && (
        <div style={{ ...styles.caretContainer, ...caretStyle }}>
          <img src={caretIcon || DEFAULT_CARET} alt="caret" />
        </div>
      )}
    </div>
  </Blink>
)

export default withRouter(LargeUserMenuButton)

const styles = {
  caretContainer: {
    transform: 'rotate(90deg)',
    width: 18
  }
}
