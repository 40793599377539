import React, { useEffect, useRef, useState } from 'react'
import { ActivityIndicator, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NextButton from '../../components/atoms/NextButton'
import { setSignUpActiveSlide } from '../../modules/signup'
import LabeledInput from '../../native/components/brand/LabeledInput'
import { brand } from '../../utils/CommonStyles'
import { biggerThan, SM_SIZE } from '../../utils/Responsiveness/sizes'

const styles = StyleSheet.create({
  imgContainer: {
    alignItems: 'center',
    marginBottom: 30
  },
  img: {
    width: 52,
    height: 52
  },
  parentContainer: {
    marginTop: 25,
    marginHorizontal: 20,
    alignItems: 'center'
  },
  slideTitle: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 24
  },
  inputsContainer: {
    marginTop: 50,
    borderBottomWidth: 0
  },
  input: {
    marginTop: 15,
    borderBottomWidth: 1,
    backgroundColor: '#252525',
    borderRadius: 5,
    shadowColor: 'rgba(100,171,255,0.70)',
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 0,
    shadowOpacity: 1,
    elevation: 1,
    height: 50,
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonText: {
    textTransform: 'uppercase',
    color: brand.color.highlightBlue,
    fontSize: 16,
    letterSpacing: 1.14,
    fontWeight: '700'
  },
  button: {
    borderColor: brand.color.highlightBlue,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderWidth: 1,
    width: 149,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 68,
    marginHorizontal: 20
  },
  disclaimerTitle: {
    color: brand.color.highlightBlue,
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: '700',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 3
  },
  disclaimerText: {
    fontSize: 13,
    color: '#fff',
    letterSpacing: 0.12,
    textAlign: 'center',
    fontWeight: '500'
  },
  disclaimerContainer: {
    marginTop: 32
  },
  navBar: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  navBarButton: {
    color: brand.color.yellow
  },
  desktopLeftArrow: {
    position: 'absolute',
    left: -200,
    bottom: 10,
    alignItems: 'center',
    flexDirection: 'row'
  },
  errorContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    marginTop: 20
  },
  errorText: {
    color: brand.color.red,
    fontWeight: '500',
    fontSize: 13,
    textAlign: 'center'
  },
  successfulResendText: {
    color: brand.color.activeGreen
  },
  succesfulResendContainer: {
    justifyContent: 'center',
    marginTop: 5
  }
})

const VerificationCodeSlide = (props) => {
  const {
    isActiveSlide,
    code,
    onChangeCode,
    onPress,
    phone,
    onResendCode,
    onVerifyVerificationCode,
    disabled,
    processing,
    validationErrors,
    validated,
    onClearOut,
    successfulResend,
    showVerifyButton,
    title = `Please enter the verification code we just texted the mobile number
      you provided.`,
    dontShowArrow
  } = props
  const [lastKey, setLastKey] = useState(null)
  const [disableNext, setDisableNext] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (isActiveSlide) {
      setDisableNext(false)
      props.setSignUpActiveSlide(6)
      inputRef.current.input.focus()
    }
  }, [isActiveSlide, props])

  return (
    <View style={[styles.parentContainer]}>
      <View style={{ maxWidth: 420 }}>
        <View style={styles.imgContainer}>
          <Image source={require('../../images/icons/phone/Grad@3x.png')} style={[styles.img, biggerThan(SM_SIZE) && { width: 72, height: 72 }]} />
        </View>
        <Text style={styles.slideTitle}>{title}</Text>
        <View style={styles.inputsContainer} className="inputs-container">
          <View
            className="just-input"
            style={[
              styles.input,
              {
                backgroundColor: '#000',
                borderBottomColor: brand.color.highlightBlue
              }
            ]}>
            <View style={[{ flex: 1 }]} className="flex-1">
              <LabeledInput
                className="labeled-input"
                editable={false}
                textContentType="telephoneNumber"
                keyboardType="phone-pad"
                clearButtonMode="always"
                autoCapitalize="words"
                returnKeyType="done"
                value={phone}
                maxLength={16}
                inputContainerStyle={{ borderBottomWidth: 0 }}
                style={[
                  {
                    borderBottomWidth: 0,
                    paddingLeft: 10,
                    textAlign: 'center',
                    color: brand.color.highlightBlue
                  }
                ]}
                testID="new_report_address_field"
              />
            </View>
          </View>
          {window.innerWidth > 1168 && !dontShowArrow && (
            <NextButton
              disabled={disableNext || disabled || !isActiveSlide}
              onPress={() => {
                if (!(disabled || !isActiveSlide)) {
                  onPress()
                }
              }}
            />
          )}
        </View>
        <View style={[styles.inputsContainer, { marginTop: 30 }]}>
          <View style={styles.input}>
            <View style={[{ flex: 1, marginBottom: 10 }]}>
              <LabeledInput
                ref={inputRef}
                tabIndex={isActiveSlide ? undefined : -1}
                editable={!processing}
                disabled={validated}
                textContentType="telephoneNumber"
                keyboardType="phone-pad"
                clearButtonMode="always"
                autoCapitalize="words"
                inputPlaceholder="Verification Code"
                returnKeyType="done"
                value={code}
                maxLength={16}
                inputContainerStyle={{ borderBottomWidth: 0 }}
                onKeyPress={(e) => {
                  setLastKey(e.key)
                }}
                onBlur={() => {
                  setDisableNext(true)
                  if (isActiveSlide && onVerifyVerificationCode) {
                    onVerifyVerificationCode()
                      // eslint-disable-next-line no-shadow
                      .then((validated) => {
                        if (validated && lastKey === 'Enter') {
                          onPress()
                        }
                      })
                      .catch(() => null)
                      .finally(() => {
                        setDisableNext(false)
                        setLastKey(null)
                      })
                  }
                }}
                onChangeText={text => onChangeCode(text)}
                style={[
                  {
                    borderBottomWidth: 0,
                    paddingLeft: 10,
                    textAlign: 'center'
                  }
                ]}
                testID="new_report_address_field"
              />
            </View>
            <View style={{ right: 20 }}>
              {processing && <ActivityIndicator />}
              {validationErrors && validationErrors.length > 0 && (
                <TouchableOpacity disabled={!isActiveSlide} onPress={onClearOut}>
                  <Image source={require('../../images/icons/close_x_yellow@3x.png')} style={{ width: 16, height: 16 }} />
                </TouchableOpacity>
              )}
              {validated && <Image source={require('../../images/icons/icon_green_checkmark@3x.png')} style={{ width: 19, height: 16 }} />}
            </View>
          </View>
        </View>
        <View style={styles.errorContainer}>
          {validationErrors &&
            validationErrors.length > 0 &&
            validationErrors.map(error => (
              <Text key={error} style={styles.errorText}>
                {error}
              </Text>
            ))}
        </View>
        {(window.innerWidth <= 1168 || showVerifyButton) && (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              disabled={!isActiveSlide}
              onPress={() => {
                if (!(disabled || !isActiveSlide)) {
                  onPress()
                }
              }}
              style={[styles.button, disabled && { opacity: 0.5, pointerEvents: 'none' }]}>
              <Text style={styles.buttonText}>Verify</Text>
            </TouchableOpacity>
          </View>
        )}
        {successfulResend && (
          <View style={[styles.succesfulResendContainer, { marginTop: 15 }]}>
            <Text style={styles.successfulResendText}>A new verification code has been sent. It is only valid for 24 hours.</Text>
          </View>
        )}
        <View style={{ alignItems: 'center', marginTop: 15 }}>
          {!validated && (
            <TouchableOpacity onPress={onResendCode} style={[(processing || validated) && { opacity: 0.5 }]} disabled={processing || validated || !isActiveSlide}>
              <Text style={{ color: brand.color.highlightBlue, fontWeight: '700' }}>RESEND CODE</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSignUpActiveSlide
    },
    dispatch
  )

export default connect(undefined, mapDispatchToProps)(VerificationCodeSlide)
