import { Box, Text, useBreakpointValue } from '@oneclickdata/components'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MOBILE_HEADER_ACTIONS } from '../../contexts/MobileHeaderContext'
import useMobileHeader from '../../customHooks/useMobileHeader'
import { BackButton } from '../BackButton'
import { ControlBarPortal } from '../ControlBar'
import { PageContainer } from '../PageContainer'
import { RenderPropForwarder } from '../utils/RenderPropForwarder'

const MobileHeaderTitle = ({ title }) => (
  <Text size="h5" bold>
    {title}
  </Text>
)

/**
 * PageHeader component to render a header with optional back button, title, tagline, description, and right-hand section.
 * @param {Object} props - Component props.
 * @param {Object} props.breakpointProps - Optional breakpoint properties for responsive design.
 * @param {boolean} props.showBackButton - Flag to show/hide the back button.
 * @param {boolean} props.inlineTitle - Flag to display the title inline.
 * @param {string} props.backButtonTitle - Title for the back button.
 * @param {string} props.backButtonRoute - Route to navigate to when the back button is pressed.
 * @param {string} props.mobileHeaderAction - Action for the mobile header.
 * @param {string|function} props.title - Title text or component.
 * @param {string|function} props.tagline - Tagline text or component.
 * @param {string|function} props.description - Description text or component.
 * @param {function} props.rightHandSection - Component to render in the right-hand section.
 * @param {boolean} props.disabled - Flag to disable the header.
 * @param {JSX.Element|JSX.Element[]} props.children - Children components to render within the header.
 * @returns {JSX.Element} The rendered PageHeader component.
 */
function PageHeader({ breakpointProps = {}, ...otherProps }) {
  const breakpointPropsClone = { ...breakpointProps }
  if (typeof breakpointPropsClone.base === 'undefined') {
    breakpointPropsClone.base = {}
  }

  const propsForBreakpoint = useBreakpointValue(breakpointPropsClone)

  const props = {
    ...otherProps,
    ...propsForBreakpoint
  }

  const {
    showBackButton = false,
    inlineTitle = false,
    backButtonTitle = 'Back',
    backButtonRoute,
    hideDefaultHeader = false,
    mobileHeaderAction = MOBILE_HEADER_ACTIONS.userMenu,
    title: Title,
    tagline: Tagline,
    description: Description,
    rightHandSection: RightHandSection,
    children,
    disabled,
    ...rest
  } = props

  const navigate = useNavigate()
  const location = useLocation()

  // This follows the Figma... but it may feel better if it doesn't change
  // see project => upgrade page and back
  // const paddingTop = children ? "$4" : "$6"
  const paddingTop = hideDefaultHeader ? undefined : '$6'
  const paddingBottom = useBreakpointValue({
    base: '$6',
    lg: children || inlineTitle ? '$4' : '$12'
  })

  const backButtonContainerMinHeight = useBreakpointValue({
    base: showBackButton ? '$12' : undefined,
    lg: children ? undefined : '$12'
  })

  const handleBackButtonPress = () => (backButtonRoute ? navigate(backButtonRoute) : navigate(-1))
  const hasHistory = location.key !== 'default'

  const shouldShowBackButton = showBackButton && (hasHistory || backButtonRoute)

  const hasStringTitle = typeof Title === 'string'

  const titleContainerMinHeight = hasStringTitle && !inlineTitle ? '$12' : undefined

  const { setMobileHeaderContext, setMobileHeaderAction, setHideDefaultHeader, setShowBackButton } = useMobileHeader()

  useEffect(() => {
    if (hasStringTitle || inlineTitle) {
      setMobileHeaderContext(<MobileHeaderTitle title={Title} />)
    }

    return () => setMobileHeaderContext(null)
  }, [Title, hasStringTitle, inlineTitle, setMobileHeaderContext])

  useEffect(() => {
    setMobileHeaderAction(mobileHeaderAction)
  }, [mobileHeaderAction, setMobileHeaderAction])

  useEffect(() => {
    setHideDefaultHeader(hideDefaultHeader)
  }, [hideDefaultHeader, setHideDefaultHeader])

  useEffect(() => {
    setShowBackButton(shouldShowBackButton)

    return () => setShowBackButton(false)
  }, [shouldShowBackButton, setShowBackButton])

  const baseDisplay = Tagline || Description || children ? 'unset' : 'none'
  const lgDisplay = 'unset'

  const stringTaglineProps = useBreakpointValue({ base: { size: 'xl' }, lg: { size: 'h2' } })
  const stringDescriptionProps = useBreakpointValue({ base: { size: 'md' }, lg: { size: 'lg' } })

  return (
    <ControlBarPortal>
      <PageContainer $base-display={baseDisplay} $lg-display={lgDisplay}>
        <Box paddingHorizontal="$4" paddingTop={paddingTop} paddingBottom={paddingBottom} {...rest}>
          <Box position="relative" minHeight={backButtonContainerMinHeight} alignItems="flex-start">
            {inlineTitle && (
              <Box $base-display="none" $lg-display="unset" alignContent="center" textAlign="center" position="absolute" h="100%" w="100%">
                {Title && (
                  <RenderPropForwarder
                    as={Title}
                    fallback={Text}
                    fallbackProps={{
                      bold: true,
                      size: 'h5'
                    }}
                  />
                )}
              </Box>
            )}

            {shouldShowBackButton && <BackButton label={backButtonTitle} onPress={handleBackButtonPress} />}
          </Box>

          <Box justifyContent="space-between" flexDirection={RightHandSection ? 'row' : undefined} alignItems={RightHandSection ? 'center' : undefined}>
            <Box gap="$4" minHeight={titleContainerMinHeight}>
              {Title && !inlineTitle && (
                <RenderPropForwarder
                  as={Title}
                  fallback={Text}
                  fallbackProps={{
                    bold: true,
                    '$base-display': 'none',
                    '$lg-display': 'unset',
                    size: 'h2'
                  }}
                />
              )}
              {Tagline && (
                <RenderPropForwarder
                  as={Tagline}
                  fallback={Text}
                  fallbackProps={{
                    bold: true,
                    ...stringTaglineProps
                  }}
                />
              )}
              {/* TODO: minHeight="22px" corrects for Safari rounding the height down when line-height is 21.6px ??? */}
              {Description && (
                <RenderPropForwarder
                  as={Description}
                  fallback={Text}
                  fallbackProps={{
                    minHeight: '22px',
                    color: '$neutral500',
                    ...stringDescriptionProps
                  }}
                />
              )}
            </Box>
            <Box>{RightHandSection && <RightHandSection />}</Box>
          </Box>
          {children}
          {disabled && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                bg: 'rgba(18, 19, 32, 0.65);',
                top: 0,
                zIndex: 1
              }}
            />
          )}
        </Box>
      </PageContainer>
    </ControlBarPortal>
  )
}

PageHeader.MOBILE_HEADER_ACTIONS = MOBILE_HEADER_ACTIONS

export { PageHeader }
