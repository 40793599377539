import React from 'react'

/**
 * RenderPropForwarder allows for a component or function to be passed as a prop.
 * This will fallback to a `Text` component if the `as` prop is a string.
 * @param {Object} props - The props object
 * @param {React.ReactNode} props.as - The component to render
 * @param {Object=} props.asProps - The props to pass to the component
 * @param {React.ReactNode=} props.fallback - The fallback component to render if `as` is a string
 * @param {Object=} props.fallbackProps - The props to pass to the fallback component
 * @returns {JSX.Element}
 */
export function RenderPropForwarder({ as: renderAs, asProps = {}, fallback: Fallback = Text, fallbackProps = {} }) {
  let node = renderAs
  if (typeof renderAs === 'function') {
    node = renderAs(asProps)
  }
  if (!node || typeof node === 'string' || typeof node === 'number' || typeof node === 'boolean') {
    node = <Fallback {...fallbackProps}>{typeof node !== 'undefined' ? node : 'undefined'}</Fallback>
  }

  return node
}
