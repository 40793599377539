/* eslint-disable global-require */
export default {
  get PDF() {
    return require('../../../images/icons/icon__pdf@3x.png')
  },
  get ThreeDots() {
    return require('../../../images/icons/threeDots@2x.png')
  },
  get Detail() {
    return require('../../../images/icons/detail@3x.png')
  },
  get RoofShingle() {
    return require('../../../images/icons/shingle_v2/white@3x.png')
  },
  get Wind() {
    return require('../../../images/icons/wind@3x.png')
  },
  get TickWrapped() {
    return require('../../../images/icons/tickWrapped@3x.png')
  },
  get HailStorm() {
    return require('../../../images/icons/hailStorm@3x.png')
  },
  get WhitePillBeta() {
    return require('../../../images/icons/beta_pill/White@3x.png')
  },
  get BluePillBeta() {
    return require('../../../images/icons/beta_pill/Blue@3x.png')
  },
  get Close() {
    return require('../../../images/icons/x_black@3x.png')
  },
  get Warning() {
    return require('../../../images/icons/warning@3x.png')
  },
  get PlansJurisdictionAsignment() {
    return require('../../../images/plansJurisdictionAsignment@3x.png')
  },
  get PlansBuildingCodeEnforcement() {
    return require('../../../images/plansBuildingCodeEnforcement@3x.png')
  },
  get PlansManufacturerRequirements() {
    return require('../../../images/plansManufacturerRequirements@3x.png')
  },
  get PlansSalesTaxCalculation() {
    return require('../../../images/plansSalesTaxCalculation@3x.png')
  },
  get WhiteCheckMark() {
    return require('../../../images/icons/white_checkmark.png')
  },
  get StatesBlue() {
    return require('../../../images/states-blue.png')
  },
  get StatesGray() {
    return require('../../../images/states-gray.png')
  },
  get LocationIcon() {
    return require('../../../images/location-icon.png')
  },
  get LocationIconGray() {
    return require('../../../images/location-icon-gray.png')
  },
  get CheckmarkYellow() {
    return require('../../../images/yellow-checkmark.png')
  },
  get YellowExclamation() {
    return require('../../../images/icons/icon_yellow_exclamation.png')
  },
  get TooltipInfo() {
    return require('../../../images/tooltip-info@3x.png')
  },
  get HouseIcon() {
    return require('../../../images/icons/house/white@3x.png')
  },
  get companyCamLogo() {
    return require('../../../images/icons/company_cam_logo.png')
  },
  get companyCamLogoWhite() {
    return require('../../../images/icons/company_cam_white.png')
  },
  get zapierLogo() {
    return require('../../../images/icons/zapier_logo.png')
  },
  get noIntegration() {
    return require('../../../images/icons/no_integration_logo.png')
  },
  get link() {
    return require('../../../images/icons/clip_link/yellow.png')
  },
  get lockWhite() {
    return require('../../../images/icons/lock_white.png')
  },
  get whiteCheckmark() {
    return require('../../../images/icons/white_checkmark.png')
  },
  get oneClickBannerSmall() {
    return require('../../../images/icons/images_logo_light_bg@3x.png')
  },
  get oneClickBannerLarge() {
    return require('../../../images/brand/OneClick Logo@3x.png')
  },
  get blueDownCaret() {
    return require('../../../images/icons/blueDownCaret@3x.png')
  },
  get bluePhone() {
    return require('../../../images/icons/phone/Grad@3x.png')
  },
  get blueWebsite() {
    return require('../../../images/icons/blue_website@3x.png')
  },
  get droneLogo() {
    return require('../../../images/icons/drone.png')
  },
  get propertyDetailsLogo() {
    return require('../../../images/icons/property_details.png')
  },
  get largeHailStormIcon() {
    return require('../../../images/icons/icon_hail_storm_large@3x.png')
  }
}
