import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const ModalPortal = ({ children, maintainTop }) => {
  const elRef = useRef(document.createElement('div'))

  useEffect(() => {
    const el = elRef.current
    el.style.opacity = '0'
    el.style.pointerEvents = 'all'
    el.style.transition = 'all 200ms'

    window.setTimeout(() => {
      el.style.opacity = '1'
    }, 5)

    const modalRoot = document.getElementById('modal-root')
    modalRoot.appendChild(el)

    const [body] = document.getElementsByTagName('body')
    body.classList.add('modal-open')

    if (maintainTop) body.style.top = `-${window.scrollY}px`

    return () => {
      if (modalRoot.contains(el)) modalRoot.removeChild(el)
      body.classList.remove('modal-open')
      body.style.top = ''
      window.scrollTo(0, window.scrollY)
    }
  }, [maintainTop])

  return ReactDOM.createPortal(children, elRef.current)
}

export default ModalPortal
