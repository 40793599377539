const textContentTypeMap = {
  URL: 'url',
  addressCity: 'address-level2',
  addressCityAndState: 'address-level1+2',
  addressState: 'address-level1',
  countryName: 'country-name',
  creditCardNumber: 'cc-number',
  emailAddress: 'email',
  familyName: 'family-name',
  fullStreetAddress: 'street-address',
  givenName: 'given-name',
  jobTitle: 'organization-title',
  location: 'location',
  middleName: 'additional-name',
  name: 'name',
  namePrefix: 'honorifix-prefix',
  nameSuffix: 'honorifix-suffix',
  nickname: 'nickname',
  organizationName: 'organization',
  postalCode: 'postal-code',
  streetAddressLine1: 'address-line1',
  streetAddressLine2: 'address-line2',
  sublocality: 'address-level3',
  telephoneNumber: 'tel',
  username: 'username',
  password: 'password',
  newPassword: 'new-password',
  oneTimeCode: 'one-time-code'
}

export { textContentTypeMap }
