import { View } from '@oneclickdata/components'
import React from 'react'
import { Outlet, Route } from 'react-router-dom'
import { ROUTES_NAMES } from '../../../routes/constants'
import Profile from '../../../routes/profile'
import CompanyDetails from '../../../routes/profileCompanyDetails'
import EmailUpdate from '../../../routes/profileEmailUpdate'
import PasswordUpdate from '../../../routes/profilePasswordUpdate'
import VerifyPhone from '../../../routes/profileVerifyPhone'
import AuthenticatedRoute from '../../../components/atoms/AuthenticatedRoute'
import withCookies from '../../../components/HOC/withCookies'

const {
  profileFull: { route: profileRoute },
  profileVerifyPhone: { route: profileVerifyPhoneRoute },
  profileUpdateEmail: { route: profileUpdateEmailRoute },
  profileCompanyDetails: { route: profileCompanyDetailsRoute },
  profileUpdatePassword: { route: profileUpdatePasswordRoute }
} = ROUTES_NAMES

const PAGES = [
  { path: profileRoute, component: Profile },
  { path: profileVerifyPhoneRoute, component: VerifyPhone },
  { path: profileUpdatePasswordRoute, component: PasswordUpdate },
  { path: profileCompanyDetailsRoute, component: CompanyDetails },
  { path: profileUpdateEmailRoute, component: EmailUpdate }
]

const ProfileLayout = () => (
  <View px="$4">
    <Outlet />
  </View>
)

const ProfileRouter = (
  <Route element={<ProfileLayout />}>
    {PAGES.map(({ path, component, ...props }) => (
      <Route key={path} path={path} element={<AuthenticatedRoute component={withCookies(component)} />} {...props} />
    ))}
  </Route>
)

export default ProfileRouter
