import glamorous from 'glamorous'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { brand } from '../../utils/CommonStyles'
import useResponsive from '../../utils/useRepsonsive'

const styles = {
  noticeLabel: {
    fontFamily: 'Barlow-Bold, Barlow',
    fontWeight: 700,
    fontSize: 11,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: brand.color.yellow,
    marginTop: 7.5
  },
  errorLabel: {
    fontFamily: 'Barlow-Bold, Barlow',
    fontWeight: 700,
    fontSize: 11,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: brand.color.red,
    marginTop: 7.5
  },
  rightSideRenderContainer: {
    position: 'absolute',
    right: 0
  }
}

export const Label = glamorous.label(
  {
    opacity: 0.5,
    fontWeight: '700',
    fontFamily: 'Barlow',
    fontSize: 10,
    color: '#FFF',
    letterSpacing: 3,
    textTransform: 'uppercase',
    transition: 'opacity 200ms',
    marginTop: 0,
    alignSelf: 'left',
    '@media only screen and (min-width: 768px) and (min-height: 500px)': {
      fontSize: 14,
      letterSpacing: 4.2,
      marginTop: 15,
      fontWeight: '700'
    }
  },
  ({ focused = false }) => ({
    opacity: focused ? 1 : 0.5
  })
)

const Input = glamorous(InputMask)(
  {
    fontWeight: '600',
    fontSize: 16,
    color: '#FFF',
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'border 200ms',
    borderBottom: '1px solid white',
    borderRadius: 0,
    paddingLeft: 0,
    width: '100%',
    paddingBottom: 5,
    marginBottom: 5,
    ':focus': {
      borderBottom: `1px solid ${brand.color.brightBlue}`,
      outline: 'none'
    },
    '@media only screen and (min-width: 768px) and (min-height: 500px)': {
      fontSize: 24,
      marginBottom: 15
    }
  },
  ({ valueColor }) => ({
    color: valueColor
  }),
  // eslint-disable-next-line no-shadow
  ({ styles }) => ({
    ...styles
  })
)

const FormInput = (props) => {
  const [focused, setFocus] = useState(false)

  const handleFocus = () => {
    setFocus(true)
    if (props.onFocus) props.onFocus()
  }

  const handleBlur = () => {
    setFocus(false)
    if (props.onBlur) props.onBlur()
  }
  const {
    errors,
    isRequired,
    valid,
    label = '.label',
    name,
    notices,
    singleError,
    labelStyle,
    valueColor,
    rightSideRender,
    rightSideRenderContainerStyle,
    requiredAsterikStyle
  } = props
  const responsive = useResponsive()

  return (
    <div style={{ position: 'relative' }}>
      <Label htmlFor={name} focused={focused} style={{ textAlign: 'left', ...labelStyle }}>
        {label}
        {isRequired && (
          <span
            style={{
              color: valid ? brand.color.darkGreen : brand.color.red,
              fontSize: 14,
              ...requiredAsterikStyle
            }}>
            *
          </span>
        )}
      </Label>
      <Input valueColor={valueColor} id={name} name={name} {...props} onFocus={handleFocus} onBlur={handleBlur} />
      {focused &&
        notices &&
        notices.map(notice => (
          <div key={notice} style={styles.noticeLabel}>
            {notice}
          </div>
        ))}
      {errors
        ? errors.map(error => (
            <span key={error} style={styles.errorLabel}>
              {error}
            </span>
          ))
        : null}
      {singleError ? <span style={styles.errorLabel}>{singleError.error}</span> : null}
      {rightSideRender && (
        <div
          style={{
            ...styles.rightSideRenderContainer,
            ...rightSideRenderContainerStyle,
            ...responsive({
              xs: {
                top: 23
              },
              sm: {
                top: 30
              }
            })
          }}>
          {rightSideRender}
        </div>
      )}
    </div>
  )
}

export default FormInput
