import { Button, ButtonSpinner, ButtonText, useToast, View, VStack } from '@oneclickdata/components'
import { FormField } from '@oneclickdata/occ-components'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Platform, StyleSheet, TextInput } from 'react-native'
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { PageHeader } from '../../components/PageHeader'
import { REQUIRED_BASE_TEXT } from '../../constants'
import analytics from '../../services/analytics'
import './index.css'

const styles = StyleSheet.create({
  hiddenTextInput: {
    height: 1,
    width: 1
  }
})

const localeText = {
  currentFieldLabel: 'Current password',
  newFieldLabel: 'New password',
  fieldLabel: 'Password',
  fieldPlaceholder: '',
  successMessage: 'Your password has been successfully updated!',
  updateCTA: 'Update password'
}

const CURRENT_PASSWORD_FIELD = 'password'
const NEW_PASSWORD_FIELD = 'newPassword'

const PasswordForm = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const { control, handleSubmit, formState, getValues, setError } = useForm({
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: { [CURRENT_PASSWORD_FIELD]: '', [NEW_PASSWORD_FIELD]: '' }
  })
  const { isSubmitting } = formState

  const [wasAutofilled, setWasAutofilled] = useState(false)
  const [previousNewPassword, setPreviousNewPassword] = useState('')

  const inputRefs = {
    currentPassword: useRef(null),
    newPassword: useRef(null)
  }

  useEffect(() => {
    analytics.logEvent('screenView', { currentScreen: 'updatePassword' })
  }, [])

  const redirectBack = () => navigate(-1)

  const submit = async () => {
    analytics.logEvent('buttonSelected', {
      buttonType: 'updatePassword'
    })

    const values = getValues()
    const { password: pass, newPassword: newPass } = values

    try {
      await api.profile.changePassword(pass, newPass)
      toast.show({
        message: localeText.successMessage,
        action: 'success'
      })
      redirectBack()
      analytics.logEvent('updatePassword')
    } catch (e) {
      let errorField = ''
      let errorMessage = ''
      if (e.message === 'Incorrect username or password.') {
        errorMessage = 'Incorrect current password'
        errorField = 'password'
      } else if (e.code === 'InvalidPasswordException' || e.message.includes("Value at 'proposedPassword' failed")) {
        errorMessage = "New password doesn't meet password requirements"
        errorField = 'newPassword'
      } else {
        toast.show({ message: e.message, action: 'error' })
        errorField = 'misc'
        errorMessage = e.message
      }

      if (errorField !== 'misc') {
        setError(errorField, {
          type: 'custom',
          message: errorMessage
        })
      }
      analytics.logEvent('updatePasswordError', {
        errorMessage: e.message
      })
    }
  }

  return (
    <View $base-mt="$8" $lg-mt="0">
      <PageHeader
        breakpointProps={{
          lg: { showBackButton: true }
        }}
        backButtonTitle="Profile"
        mobileHeaderAction={PageHeader.MOBILE_HEADER_ACTIONS.backOnce}
        inlineTitle
        title="Change Password"
      />
      <VStack maxW={552} w="100%" mx="auto" space="2xl">
        <View>
          <Controller
            control={control}
            name={CURRENT_PASSWORD_FIELD}
            rules={{
              required: 'Required field',
              min: 5
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField.Password
                {...field}
                textContentType="password"
                type="password"
                error={error?.message}
                isDisabled={isSubmitting}
                onChangeText={field.onChange}
                label={localeText.currentFieldLabel}
                placeholder={localeText.fieldPlaceholder}
              />
            )}
          />
          {Platform.OS === 'ios' && <TextInput style={styles.hiddenTextInput} onFocus={() => inputRefs.newPassword.current?.focus?.()} />}
        </View>

        <Controller
          control={control}
          name={NEW_PASSWORD_FIELD}
          rules={{
            required: REQUIRED_BASE_TEXT,
            validate: FormField.Password.validate
          }}
          render={({ field, fieldState: { error, invalid, isDirty } }) => {
            let errors = error?.message
            if (field.value !== '' && error?.types && Object.keys(error.types).length > 0) {
              errors = error.types
            }
            return (
              <FormField.Password
                {...field}
                ref={inputRefs.newPassword}
                textContentType="newPassword"
                type="password"
                showRules={(field.value && invalid) || isDirty}
                error={errors}
                color={wasAutofilled ? '#000' : undefined}
                isDisabled={isSubmitting}
                onChangeText={(text) => {
                  if (text?.length > 4 && previousNewPassword === '') {
                    setWasAutofilled(true)
                  } else if (text.length === 1 && previousNewPassword !== '') {
                    setWasAutofilled(false)
                  }
                  setPreviousNewPassword(text)
                  field.onChange(text)
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleSubmit(submit)()
                  }
                }}
                label="New Password"
                placeholder={localeText.fieldPlaceholder}
              />
            )
          }}
        />
        <Button my="$4" isDisabled={isSubmitting} onPress={handleSubmit(submit)}>
          {isSubmitting ? <ButtonSpinner /> : <ButtonText>{localeText.updateCTA}</ButtonText>}
        </Button>
      </VStack>
    </View>
  )
}

export default PasswordForm
