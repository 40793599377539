import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const RedirectedComponent = () => {
  const { firstLoginEver, loginState } = useSelector(({ auth }) => ({
    firstLoginEver: auth.firstLoginEver,
    loginState: auth.loginState
  }))

  const redirect_to = firstLoginEver ? '/introduce-yourself' : sessionStorage.getItem('redirect_to') || '/dashboard'
  return <Navigate replace to={redirect_to} state={loginState} />
}

const NonAuthenticatedRoute = ({ component: Component }) => {
  const loggedIn = useSelector(({ auth }) => auth.loggedIn)
  return loggedIn ? <RedirectedComponent /> : <Component />
}

export default NonAuthenticatedRoute
