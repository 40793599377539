import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import doubleCB from './doubleCB'

class DoubleControlBar extends React.Component {
  render() {
    return <div ref={div => (doubleCB.el = div)} style={{ paddingRight: 0, paddingLeft: 0 }} />
  }
}

const DoubleControlBarPortal = ({ children }) => {
  const elRef = useRef(document.createElement('div'))

  useEffect(() => {
    const el = elRef.current

    if (doubleCB.el) {
      while (doubleCB.el.lastChild) {
        doubleCB.el.removeChild(doubleCB.el.lastChild)
      }
      doubleCB.el.appendChild(el)
    }

    return () => {
      if (doubleCB.el && doubleCB.el.contains(el)) {
        doubleCB.el.removeChild(el)
      }
    }
  }, [])

  return ReactDOM.createPortal(children, elRef.current)
}

export default DoubleControlBar

export { DoubleControlBarPortal }
