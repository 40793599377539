import React from 'react'
import { useNavigate } from 'react-router-dom'
import Flashy from './flashy'

const Blink = ({ to, onClick, whenFollowed, ...rest }) => {
  const navigate = useNavigate()
  return <Flashy onClick={to ? () => navigate(to) : onClick} {...rest} whenFollowed={whenFollowed} />
}

export default Blink
