import React from 'react'
import Media from 'react-media'
import { useNavigate } from 'react-router-dom'
import { Plus } from '.'
import Flashy from './flashy'

const FloatingNewButton = () => {
  const navigate = useNavigate()
  return (
    <Media
      query="(max-width: 812px) and (max-height: 812px)"
      render={() => (
        <Flashy
          enlarge
          onClick={() => navigate('/dashboard/new')}
          id="oneclick-button-floating_new"
          style={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100000,
            bottom: '2em',
            right: '2em',
            backgroundColor: 'rgba(100, 171, 255, .4)',
            height: '5em',
            width: '5em',
            borderRadius: '100%'
          }}>
          <div>
            <img src={Plus} alt="New Project" />
          </div>
        </Flashy>
      )}
    />
  )
}

export default FloatingNewButton
