import { API, Auth } from 'aws-amplify'
import axios from 'axios'

const BASE_PATH = '/v2/calculators'

const getApiVersion = () => {
  const versions = {
    default: 'v2.dev',
    beta: 'v2.beta',
    master: 'v2'
  }

  return versions[process.env.REACT_APP_ENV] || versions.default
}

const manager = {
  saveCalculation: async (details) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/calculate`, {
      body: { ...details }
    })

    return apiResponse
  },

  getWasteEstimates: async (body) => {
    const apiVersion = getApiVersion()
    const ENDPOINT = `https://api.oneclickcode.com/${apiVersion}/public/calculators/waste_estimates`

    const { data } = await axios.post(ENDPOINT, body)

    return data
  }
}

export default manager
