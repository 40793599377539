import React from 'react'
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { brand, fonts } from '../../../utils/CommonStyles'
import TextInput from './TextInput'

const styles = StyleSheet.create({
  input: {
    height: 38,
    marginTop: 0,
    outline: 'none',
    outlineColor: 'transparent'
  },
  label: {
    fontSize: 11,
    letterSpacing: 3,
    color: '#fff',
    opacity: 0.7,
    backgroundColor: 'transparent'
  },
  labelFocused: {
    opacity: 1
  },
  clearX: {
    position: 'absolute',
    padding: 10,
    right: 0,
    backgroundColor: 'red',
    justifyContent: 'center',
    height: '100%',
    zIndex: 100
  },
  editLabel: {
    fontSize: 16,
    color: brand.color.highlightBlue,
    fontWeight: '600'
  },
  textInputContainer: {
    transition: 'opacity 200ms',
    backgroundColor: 'transparent',
    borderBottomColor: 'white',
    borderBottomWidth: 1
  }
})

class LabeledInput extends React.Component {
  state = {
    focused: false
  }

  // eslint-disable-next-line react/destructuring-assignment
  onFocus = () => this.setState({ focused: true }, () => this.props.onFocus && this.props.onFocus())

  onBlur = (e) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.setState({ focused: false }, () => this.props.onBlur && this.props.onBlur(e))
  }

  render() {
    const {
      placeholder,
      placeholderStyle,
      style,
      spinner = false,
      renderEdit,
      editLabel,
      onEditPress,
      focusOnEdit = false,
      // eslint-disable-next-line no-unused-vars
      inputRef,
      selectTextOnFocus,
      inputContainerStyle,
      mainContainerStyle,
      inputPlaceholder,
      isRequired,
      requiredAsterikStyle,
      valid,
      rightSideRender,
      rightSideRenderContainerStyle,
      ...rest
    } = this.props
    const { focused } = this.state

    return (
      <View style={[{ marginTop: 12 }, mainContainerStyle]}>
        {!!placeholder && (
          <Text style={[fonts.base, fonts.bold, styles.label, placeholderStyle, focused ? styles.labelFocused : null, { transition: 'opacity 200ms' }]}>
            {placeholder.toUpperCase()}
            {isRequired && (
              <Text
                style={[
                  {
                    color: valid ? brand.color.darkGreen : brand.color.red,
                    fontSize: 14,
                    lineHeight: 11
                  },
                  requiredAsterikStyle
                ]}>
                *
              </Text>
            )}
          </Text>
        )}
        <View
          style={[
            styles.textInputContainer,
            inputContainerStyle,
            { flexDirection: 'row', alignItems: 'center' },
            focused ? { borderBottomColor: brand.color.highlightBlue } : null,
            { transition: 'all 200ms' }
          ]}>
          <TextInput
            {...rest}
            // eslint-disable-next-line no-shadow
            ref={(ref) => {
              if (ref) {
                this.input = ref.input
              }
            }}
            style={[styles.input, style, { outlineStyle: 'none' }]}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            focused={focused}
            selectTextOnFocus={selectTextOnFocus}
            clearButtonMode="while-editing"
            placeholder={inputPlaceholder}
          />
          {rightSideRender && <View style={[{ position: 'absolute', right: 0 }, rightSideRender && rightSideRenderContainerStyle]}>{rightSideRender}</View>}
          {!rightSideRender && <ActivityIndicator animating={spinner} style={{ marginRight: 5, display: 'none' }} />}
          {renderEdit && (
            <TouchableOpacity
              onPress={() => {
                if (focusOnEdit) this.input.focus()
                onEditPress()
              }}>
              <Text style={styles.editLabel}>{editLabel || 'Edit'}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    )
  }
}

export default LabeledInput
