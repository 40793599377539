/* eslint-disable no-unreachable */
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, ButtonSpinner, ButtonText, CloseIcon, Icon, Link, LinkText, Pressable, Text, useToast, VStack } from '@oneclickdata/components'
import { FormField } from '@oneclickdata/occ-components'
import React, { useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import API from '../../api'
import { EMAIL_FIELD, schema as emailSchema } from '../../components/EmailInput'
import { PageHeader } from '../../components/PageHeader'
import { save } from '../../modules/profile'
import analytics from '../../services/analytics'

const schema = emailSchema

function EmailSentScreen({ email, onResend }) {
  return (
    <VStack maxW="550" mx="auto" gap="$10">
      <Text size="md">
        To verify the email change, please click the link we sent to{' '}
        <Text size="md" bold>
          {email}
        </Text>{' '}
        to confirm your email.
      </Text>
      <VStack space="lg">
        <Text color="$neutral500">
          <Text color="$neutral500" bold>
            Didn't receive email?
          </Text>{' '}
          Make sure to double check the email you gave us and your spam folder.
        </Text>
        <Text color="$neutral500">
          <Text color="$neutral500" bold>
            Still can't find it?
          </Text>{' '}
          <Link onPress={onResend}>
            <LinkText textDecoration="none">Resend verification email</LinkText>
          </Link>
          , or contact{' '}
          <Link href="mailto:code@oneclickcode.com">
            <LinkText>code@oneclickcode.com</LinkText>
          </Link>{' '}
          for support.
        </Text>
        <Text color="$neutral500">
          Learn more about{' '}
          <RouterLink
            to={{
              pathname: 'https://help.oneclickcode.com/how-to-verify-your-oneclick-code-account'
            }}
            target="_blank"
            rel="noopener noreferrer">
            <Text color="$cyan400">account verification</Text>.
          </RouterLink>
        </Text>
      </VStack>
    </VStack>
  )
}
const EmailForm = ({ source, appleId, profile, microsoftId }) => {
  const [processing, setProcessing] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const toast = useToast()
  const form = useForm({
    shouldFocusError: false,
    resolver: yupResolver(schema),
    defaultValues: { [EMAIL_FIELD]: '' }
  })
  const { formState, handleSubmit } = form

  useEffect(() => {
    analytics.logEvent('screenView', { currentScreen: 'ChangeEmail' })
  }, [])

  const emailValidator = async (email) => {
    const response = await API.verifyEmailAvailable(email)
    let errMessage = ''
    if (response.status !== 'ok') {
      const status = response.status ?? ''
      if (status === false) {
        errMessage = 'Invalid Email'
      } else if (status.includes('account')) {
        errMessage = 'Email Already In Use'
      } else if (response.message !== '') {
        errMessage = 'Invalid Email'
      }
    }

    return errMessage
  }

  const changeEmail = async () => {
    try {
      await API.profile.changeEmail(form.getValues().email)
      toast.show({
        message: 'Verification code sent. Please check your email.',
        action: 'success'
      })
      setEmailSent(true)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('ERROR:', err)
      analytics.logEvent('appError', {
        errorMessage: err,
        errorType: 'emailUpdateError'
      })
      toast.show({
        message: 'Something went wrong. Please try again later.',
        action: 'error'
      })
    } finally {
      setProcessing(false)
    }
  }

  const submitEmailChange = async (formData) => {
    analytics.logEvent('buttonSelected', { buttonType: 'changeEmail' })
    setProcessing(true)
    const errMessage = await emailValidator(formData.email)
    if (errMessage) {
      form.setError(EMAIL_FIELD, {
        type: 'manual',
        message: errMessage
      })
      setProcessing(false)

      return
    }
    await changeEmail()
  }

  const { email: emailValue } = form.watch()

  return (
    <Box $base-mt="$8" $lg-mt="0">
      <PageHeader
        inlineTitle
        breakpointProps={{
          lg: { showBackButton: true }
        }}
        backButtonTitle="Profile"
        mobileHeaderAction={PageHeader.MOBILE_HEADER_ACTIONS.backOnce}
        title={emailSent ? 'Verify Your New Email' : 'Change Email'}
      />
      <FormProvider {...form}>
        <DocumentTitle title="OneClickCode | Change Email" />
        {emailSent ? (
          <EmailSentScreen email={emailValue} onResend={changeEmail} />
        ) : (
          <VStack maxW={550} w="100%" mx="auto" space="2xl">
            <Text>Your current email is {profile?.email ?? ''}. Enter a new one below:</Text>
            {source === 'federated' && (
              <Text>
                You have signed in with {appleId ? 'Apple' : microsoftId ? 'Microsoft' : 'Google'}. Please use
                {appleId ? 'Apple' : microsoftId ? 'Microsoft' : 'Google'}'s services to update your email.
              </Text>
            )}
            {source === 'cognito' && (
              <VStack space="2xl">
                <VStack space="sm">
                  <Controller
                    control={form.control}
                    name={EMAIL_FIELD}
                    render={({ field, fieldState }) => (
                      <FormField.Input
                        {...field}
                        placeholder="email@business.com"
                        disabled={formState.isSubmitting}
                        keyboardType="email-address"
                        textContentType="emailAddress"
                        error={fieldState?.error?.message}
                        hideRightElement={!field.value}
                        InputRightElement={
                          <Pressable onPress={() => field.onChange('')} p="$2">
                            <Icon as={CloseIcon} size="lg" />
                          </Pressable>
                        }
                        label="Email"
                      />
                    )}
                  />
                  <Text size="sm" medium color="$neutral500">
                    Do not forget that your e-mail is also your username
                  </Text>
                </VStack>
                <Button size="lg" onPress={handleSubmit(submitEmailChange)} isDisabled={processing}>
                  {processing ? <ButtonSpinner /> : <ButtonText>Submit</ButtonText>}
                </Button>
              </VStack>
            )}
          </VStack>
        )}
      </FormProvider>
    </Box>
  )
}

const mapStateToProps = ({ profile }) => ({
  source: profile.source,
  appleId: profile?.appleId,
  microsoftId: profile?.microsoftId,
  profile
})

const mapDispatchToProps = dispatch => bindActionCreators({ save }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm)
