import analytics, { registerAnalyticsHandler } from './analytics'
import { commandbarHandler, consoleHandler, firebaseHandler, pendoHandler, segmentHandler } from './handlers'

registerAnalyticsHandler(firebaseHandler, true)
registerAnalyticsHandler(consoleHandler, true)
registerAnalyticsHandler(commandbarHandler, process.env.REACT_APP_COMMANDBAR_ORG_ID)
registerAnalyticsHandler(segmentHandler, window.analytics !== undefined)
registerAnalyticsHandler(pendoHandler, window.pendo !== undefined)

export default analytics
