const SHOW_WALKTHROUGH = 'walkthrough/SHOW'
const HIDE_WALKTHROUGH = 'walkthrough/HIDE'
const NEXT_SLIDE = 'walkthrough/NEXT_SLIDE'
const PREV_SLIDE = 'walkthrough/PREV_SLIDE'

const initialState = {
  show: false,
  slide: -1
}

export default (state = initialState, { type }) => {
  switch (type) {
    case SHOW_WALKTHROUGH:
      return {
        ...state,
        show: true,
        slide: 0
      }
    case HIDE_WALKTHROUGH:
      return {
        ...state,
        show: false,
        slide: -1
      }
    case NEXT_SLIDE:
      return {
        ...state,
        slide: state.slide + 1
      }
    case PREV_SLIDE:
      return {
        ...state,
        slide: state.slide - 1
      }
    default:
      return state
  }
}

export const showWalkthroughOnFirstLogin = navigate => async (dispatch) => {
  const shown = localStorage.getItem('walkthroughShown')
  if (!shown) dispatch(showWalkthrough(navigate))
}

export const showWalkthrough = navigate => async (dispatch) => {
  navigate('/dashboard')
  dispatch({
    type: SHOW_WALKTHROUGH
  })
}

export const hideWalkthrough = () => async (dispatch) => {
  localStorage.setItem('walkthroughShown', true)
  dispatch({
    type: HIDE_WALKTHROUGH
  })
}

export const nextSlide = () => async (dispatch) => {
  dispatch({
    type: NEXT_SLIDE
  })
}

export const prevSlide = () => async (dispatch) => {
  dispatch({
    type: PREV_SLIDE
  })
}
