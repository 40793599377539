/* eslint-disable react/destructuring-assignment, no-shadow */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TinyMask from 'tinymask'
import FormButton from '../../components/FormButton'
import FormInput from '../../components/FormInput'
import { save } from '../../modules/profile'
import Text from '../../native/components/brand/Text'
import { fonts } from '../../utils/CommonStyles'
import './index.css'

const phoneFormat = new TinyMask('999.999.9999')
const maskPhone = phone => (phone ? phoneFormat.mask(phone.replace('+1', '')) : undefined)
const unmaskPhone = phone => `+1${phone.split('.').join('')}`

const styles = {
  pageHeader: {
    fontSize: 36,
    color: '#FFFFFF',
    letterSpacing: 0,
    lineHeight: 34
  },
  stateAndZipStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}

const MobileCompanyDetails = (props) => {
  const [company, setCompany] = useState(props.company)
  const [street, setStreet] = useState(props.companyStreet)
  const [city, setCity] = useState(props.companyCity)
  const [state, setState] = useState(props.companyState)
  const [zipcode, setZipcode] = useState(props.companyZip)

  const handleSave = () => {
    const { save } = props

    const updatedProfile = {
      company,
      companyStreet: street,
      companyCity: city,
      companyState: state,
      companyZip: zipcode,
      email: props.email,
      familyName: props.familyName,
      givenName: props.givenName,
      name: `${props.givenName} ${props.familyName}`,
      phone: unmaskPhone(props.phone),
      phoneVerified: props.phoneVerified,
      photoURL: props.photoURL,
      source: props.source
    }
    save(updatedProfile)
  }

  return (
    <div>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <div style={{ marginBottom: 10 }}>
          <Text style={[fonts.base, fonts.semiBold, styles.pageHeader]}>Company Details</Text>
        </div>
        <FormInput label="Company Name" value={company} onChange={e => setCompany(e.target.value)} />
        <FormInput label="Street" value={street} onChange={e => setStreet(e.target.value)} />
        <FormInput label="City" value={city} onChange={e => setCity(e.target.value)} />
        <div style={styles.stateAndZipStyle}>
          <div style={{ width: '50%', marginRight: 10 }}>
            <FormInput label="State" value={state} onChange={e => setState(e.target.value)} />
          </div>
          <div style={{ width: '50%', marginLeft: 10 }}>
            <FormInput label="Zipcode" value={zipcode} onChange={e => setZipcode(e.target.value)} />
          </div>
        </div>
        <FormButton title="Save" style={{ width: 200, minWidth: 0 }} onClick={handleSave} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ profile }) => ({
  company: profile.company,
  companyStreet: profile.companyStreet,
  companyCity: profile.companyCity,
  companyState: profile.companyState,
  companyZip: profile.companyZip,
  email: profile.email,
  name: profile.name,
  givenName: profile.givenName,
  familyName: profile.familyName,
  phone: maskPhone(profile.phone),
  phoneVerified: profile.phoneVerified,
  photoURL: profile.photoURL,
  source: profile.source
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      save
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(MobileCompanyDetails)
