import React from 'react'
import { StyleSheet, TextInput } from 'react-native'
import { brand } from '../../../utils/CommonStyles'
import { textContentTypeMap } from '../../../utils/textContentTypeMap'

const styles = StyleSheet.create({
  brand: {
    fontFamily: 'Barlow',
    fontSize: 17,
    color: 'white',
    marginTop: 12,
    height: 46,
    width: '100%'
  },
  focused: {
    borderBottomColor: brand.color.brightBlue
  }
})

class BrandTextInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: props.focused || false
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (newProps.focused !== this.props.focused) {
      this.setState({ focused: newProps.focused })
    }
  }

  onBlur = () => this.setState({ focused: false })

  onFocus = () => this.setState({ focused: true })

  handleChangeText = (text) => {
    // eslint-disable-next-line react/destructuring-assignment
    const finalText = this.props.mask ? this.applyMask(this.props.mask, text) : text

    // eslint-disable-next-line react/destructuring-assignment
    this.props.onChangeText && this.props.onChangeText(finalText)
  }

  applyMask = (mask, value) => value

  render() {
    const { focusedStyle, style, onBlur, onFocus, textContentType, ...props } = this.props

    const { focused } = this.state

    const opacity = props.value !== '' ? 1 : null

    return (
      <TextInput
        ref={ref => (this.input = ref)}
        {...props}
        testID={props['data-testid']}
        textContentType={textContentTypeMap[textContentType]}
        placeholderTextColor="#797979"
        selectionColor="white"
        onFocus={onFocus || this.onFocus}
        onBlur={onBlur || this.onBlur}
        onChangeText={this.handleChangeText}
        style={[styles.brand, opacity ? { opacity } : null, focused ? styles.focused : null, style, focused ? focusedStyle : null]}
      />
    )
  }
}

export default BrandTextInput
