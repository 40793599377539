import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonSpinner, ButtonText, HStack, Text, useToast, View, VStack } from '@oneclickdata/components'
import { Alert, FormField } from '@oneclickdata/occ-components'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import API from '../../api'
import { PageHeader } from '../../components/PageHeader'
import { loadProfile, save as saveProfile } from '../../modules/profile'
import analytics from '../../services/analytics'
import { toAWSPhone, toDisplayPhone } from '../../utils/phoneMask'
import { ROUTES_NAMES } from '../constants'
import './index.css'
import localeText from './localeText.json'

const CustomerIdBlock = ({ userId, signUpTimestamp }) => {
  const toast = useToast()
  const userIdDisplay = userId.replace('us-east-1:', '')

  return (
    <HStack backgroundColor="$backgroundBoxes" borderColor="$backgroundInput" borderWidth="$1" padding="$4" borderRadius="$2xl">
      <View flex={1}>
        <View flexDirection="row">
          <View flex={1}>
            <Text size="md" color="white" isTruncated>
              ID:
              {userIdDisplay}
            </Text>
          </View>
        </View>
        <View>
          <Text size="xs" color="$neutral500">
            Member since: {new Date(signUpTimestamp).toLocaleDateString()}
          </Text>
        </View>
      </View>
      <View justifyContent="center" ml="$8">
        <Button
          size="xs"
          variant="link"
          onPress={async () => {
            toast.show({
              message: 'Your Support ID has been copied successfully.',
              action: 'success'
            })
            await navigator.clipboard.writeText(userIdDisplay)
          }}>
          <ButtonText>Copy</ButtonText>
        </Button>
      </View>
    </HStack>
  )
}

const {
  profileUpdatePassword: { route: updatePassword }
} = ROUTES_NAMES
const PROFESSION_TYPE_FIELD = 'profession'
const FIRST_NAME_FIELD = 'givenName'
const PHONE_FIELD = 'phone'
const EMAIL_FIELD = 'email'
const nonTollPattern = /^1 \(((?!111|222|333|444|555|666|777|888|999|800|833|844|855|866|877)[2-9]\d{2})\) ([\s\-./]?(?!555)\d{3})([\s\-./]?\d{4})$/
const schema = yup.object({
  [FIRST_NAME_FIELD]: yup.string(),
  [PROFESSION_TYPE_FIELD]: yup.object(),
  [PHONE_FIELD]: yup.lazy(value => (!value ? yup.string() : yup.string().matches(nonTollPattern, 'Phone number must be a valid')))
})

const UserProfile = () => {
  const toast = useToast()
  const profile = useSelector(state => state.profile)
  const dispatch = useDispatch()
  const { givenName, professionType, phone, email } = profile
  const [isMounted, setIsMounted] = useState(true)
  const [professionChoices, setProfessionChoices] = useState([])
  const navigate = useNavigate()
  const location = useLocation()

  // Check if the user is coming from the email update flow. If so, check if the code is valid and update the email
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const code = query.has('code') ? decodeURIComponent(query.get('code')) : location?.state?.code
    const emailParam = query.has('email') ? decodeURIComponent(query.get('email')) : null
    // eslint-disable-next-line no-shadow
    async function confirmEmail(code) {
      try {
        await API.profile.verifyEmailChange(code)
        toast.show({
          message: 'Success! Your email has been changed',
          action: 'success'
        })
        dispatch(loadProfile())
      } catch (e) {
        let errorMessage = 'Link has expired'
        if (emailParam) {
          if (emailParam !== profile.email) {
            errorMessage = "You're signed in with a different account. Log in using your old email."
          }
        }
        toast.show({ message: errorMessage, action: 'error' })
      }
      if (query.has('code')) {
        query.delete('code')
      }
      if (query.has('email')) {
        query.delete('email')
      }
      navigate({ search: createSearchParams(query).toString() }, { replace: true })
    }
    if (code) {
      confirmEmail(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const DEFAULT_VALUES = {
    [PROFESSION_TYPE_FIELD]: '',
    [FIRST_NAME_FIELD]: givenName,
    [PHONE_FIELD]: toDisplayPhone(phone),
    [EMAIL_FIELD]: email
  }
  const { control, handleSubmit, getValues, setValue, reset, formState } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES
  })
  const { isDirty, isValid, isSubmitting } = formState
  const { source: profileSource, appleId, microsoftId, identity_provider_type } = profile

  useEffect(() => {
    if (profile?.email) {
      setValue(EMAIL_FIELD, profile.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.email])

  let accountType
  if (identity_provider_type === 'SAML') {
    accountType = 'saml'
  } else if (profileSource === 'cognito') {
    accountType = profileSource
  } else if (typeof appleId !== 'undefined') {
    accountType = 'apple'
  } else if (typeof microsoftId !== 'undefined') {
    accountType = 'microsoft'
  } else {
    accountType = 'google'
  }
  const inputRefs = { givenName: useRef(), profession: useRef(), phone: useRef() }

  useEffect(() => {
    analytics.logEvent('screenView', { currentScreen: 'Profile' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => () => setIsMounted(false), [])

  useEffect(() => {
    const fetchChoices = async () => {
      try {
        const response = await API.publicConfig.get('professionTypes')
        if (response.status === 200) {
          const { item } = response
          setProfessionChoices(item)
          setValue(
            PROFESSION_TYPE_FIELD,
            item?.find(i => i.value === professionType)
          )
        } else {
          // eslint-disable-next-line no-console
          console.log('ERROR:', response.message)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
    fetchChoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = async () => {
    const { familyName, source } = profile
    const values = getValues()
    Object.keys(values).forEach(key => setValue(key, values[key]?.trim?.()))
    const givenNameCapitalized = _.capitalize(values[FIRST_NAME_FIELD])
    const names = [givenNameCapitalized, familyName].filter(item => !!item)
    const profileUpdates = {
      source,
      ...(source === 'cognito' && { email: values[EMAIL_FIELD] }),
      givenName: givenNameCapitalized,
      phone: toAWSPhone(values[PHONE_FIELD]),
      professionType: professionChoices?.find(item => item.value === values[PROFESSION_TYPE_FIELD]?.value)?.value,
      name: names.join(' ')
    }
    await dispatch(saveProfile(profileUpdates))
    if (isMounted) {
      reset({
        [PROFESSION_TYPE_FIELD]: values[PROFESSION_TYPE_FIELD],
        [FIRST_NAME_FIELD]: values[FIRST_NAME_FIELD]?.trim(),
        [PHONE_FIELD]: values[PHONE_FIELD]?.trim(),
        [EMAIL_FIELD]: values[EMAIL_FIELD]?.trim()
      })
    }
    toast.show({ message: localeText.saveSuccess, action: 'success' })

    return Promise.resolve(undefined)
  }
  const onChangeEmailClick = () => {
    navigate(ROUTES_NAMES.profileUpdateEmail.route)
  }
  const notices = []
  if (profile.source === 'cognito') {
    notices.push(`An active cell phone capable of receiving SMS text messages is needed to verify your account.`)
  }

  const canChangePassword = profile.source === 'cognito' && !profile.identity_provider_type
  const canChangeEmail = profile.source === 'cognito' && !profile.identity_provider_type

  return (
    <View $base-mt="$8" $lg-mt="0">
      <PageHeader inlineTitle title="Profile" />
      <VStack maxWidth="552px" w="100%" mx="auto" space="2xl" mb="$4">
        <Controller
          control={control}
          name={FIRST_NAME_FIELD}
          render={({ field, fieldState }) => (
            <FormField.Input
              {...field}
              ref={inputRefs.givenName}
              textContentType="givenName"
              error={fieldState.error?.message}
              onChangeText={field.onChange}
              label={localeText.firstNameLabel}
              placeholder={localeText.firstNamePlaceholder}
              autoCapitalize="words"
              style={{ textTransform: 'capitalize' }}
            />
          )}
        />
        <CustomerIdBlock userId={profile.userId} signUpTimestamp={profile.signUpTimestamp} />
        <Controller
          control={control}
          name={PROFESSION_TYPE_FIELD}
          render={({ field }) => (
            <FormField.Select
              name={field.name}
              onBlur={field.onBlur}
              value={professionChoices?.find(profession => profession.value === field.value.value)}
              mb="4"
              ref={inputRefs.profession}
              label={localeText.positionLabel}
              isDisabled={isSubmitting}
              placeholder={localeText.positionPlaceholder}
              onChange={(newValue) => {
                const matchedItem = professionChoices?.find(item => item.value === newValue.value)
                field.onChange(matchedItem)
              }}
              options={professionChoices}
            />
          )}
        />
        <Controller
          control={control}
          name={PHONE_FIELD}
          render={({ field, fieldState }) => (
            <FormField.Input
              {...field}
              ref={inputRefs.phone}
              textContentType="telephoneNumber"
              error={fieldState.error?.message}
              onChangeText={(value) => {
                const maskedValue = toDisplayPhone(value)
                field.onChange(maskedValue)
              }}
              maxLength={16}
              keyboardType="phone-pad"
              label={localeText.phoneLabel}
              placeholder={localeText.phonePlaceholder}
              autoCapitalize="words"
            />
          )}
        />
        <Controller
          control={control}
          name={EMAIL_FIELD}
          render={({ field, fieldState }) => (
            <FormField.Input
              {...field}
              ref={inputRefs.email}
              isDisabled
              error={fieldState.error?.message}
              onChangeText={field.onChange}
              label={localeText.emailLabel}
              placeholder={localeText.emailPlaceholder}
              InputRightElement={
                canChangeEmail && (
                  <Button w="auto" variant="link" size="sm" onPress={onChangeEmailClick}>
                    <ButtonText>Change Email</ButtonText>
                  </Button>
                )
              }
            />
          )}
        />
        {!canChangePassword && <Alert status="info" title="Info" message={localeText.emailAndPasswordUpdate[accountType]} />}
        {canChangePassword && (
          <HStack justifyContent="space-between">
            <View justifyContent="space-around">
              <Text size="xs">Password</Text>
            </View>
            <View>
              <Button size="sm" variant="link" onPress={() => navigate(updatePassword)}>
                <ButtonText>Change password</ButtonText>
              </Button>
            </View>
          </HStack>
        )}
        <Button onPress={handleSubmit(save)} isDisabled={isSubmitting || !isDirty ? true : !isValid}>
          {isSubmitting ? <ButtonSpinner /> : <ButtonText>{localeText.buttonCTA}</ButtonText>}
        </Button>
      </VStack>
    </View>
  )
}

export default UserProfile
