/* eslint-disable max-classes-per-file */
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import cbElHolder from './cbEl'

class ControlBar extends React.Component {
  render() {
    return <div ref={div => (cbElHolder.el = div)} />
  }
}

export default ControlBar

const ControlBarPortal = ({ children }) => {
  const elRef = useRef(document.createElement('div'))

  useEffect(() => {
    const el = elRef.current
    el.style.padding = '0px'
    el.style.margin = '0px'

    if (cbElHolder.el) {
      while (cbElHolder.el.lastChild) {
        cbElHolder.el.removeChild(cbElHolder.el.lastChild)
      }
      cbElHolder.el.appendChild(el)
    }

    return () => {
      if (cbElHolder.el && cbElHolder.el.contains(el)) {
        cbElHolder.el.removeChild(el)
      }
    }
  }, [])

  return ReactDOM.createPortal(children, elRef.current)
}

export { ControlBarPortal }
