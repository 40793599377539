/* eslint-disable no-shadow */
import React, { useState } from 'react'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { loadProfile, requestVerifyNewPhoneCode, submitNewPhoneVerificationCode } from '../../modules/profile'
import { toDisplayPhone } from '../../utils/phoneMask'
import { ROUTES_NAMES } from '../constants'
import VerificationCodeSlide from '../signup/VerificationCodeSlide'

const {
  profileFull: { route: profileRoute }
} = ROUTES_NAMES

const PasswordForm = ({ requestVerifyNewPhoneCode, submitNewPhoneVerificationCode, phone, loadProfile }) => {
  const navigate = useNavigate()
  const [verificationCode, setVerificationCode] = useState('')
  const [resendCodeSuccess, setResendCodeSuccess] = useState(false)
  const [verificationCodeValid, setVerificationCodeValid] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const redirectToProfile = () => navigate(profileRoute)

  const requestNewCode = async () => {
    if (resendCodeSuccess) setResendCodeSuccess(false)
    setProcessing(true)
    try {
      await requestVerifyNewPhoneCode()
      setResendCodeSuccess(true)
    } catch (e) {
      setResendCodeSuccess(false)
      // eslint-disable-next-line no-console
      console.log('Failed to send new verification code')
    } finally {
      setProcessing(false)
    }
  }
  const handleVerify = async () => {
    setVerificationCodeValid(null)
    setValidationErrors([])
    setResendCodeSuccess(false)
    if (!verificationCode) return false
    setProcessing(true)
    try {
      await submitNewPhoneVerificationCode(verificationCode)
      setProcessing(false)
      setVerificationCodeValid(true)
      await loadProfile()
      redirectToProfile()
    } catch (e) {
      setProcessing(false)
      setVerificationCodeValid(false)
      setValidationErrors(['Invalid verification code provided, please try again'])
      // eslint-disable-next-line no-console
      console.log('Failure submitting new phone verification code', e)
    }
  }

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      <DocumentTitle title="OneClickCode | Verify Phone" />
      <VerificationCodeSlide
        code={verificationCode}
        isActiveSlide
        processing={processing}
        dontShowArrow
        successfulResend={resendCodeSuccess}
        onResendCode={() => requestNewCode()}
        phone={toDisplayPhone(phone)}
        showVerifyButton
        onVerifyVerificationCode={handleVerify}
        onChangeCode={text => setVerificationCode(text)}
        disabled={!verificationCode || processing || verificationCodeValid}
        validated={verificationCodeValid}
        validationErrors={validationErrors}
        mainContainerStyle={{ marginHorizontal: 'unset' }}
        title="Please enter the verification code we sent to your new phone number."
        onClearOut={() => {
          setVerificationCodeValid(null)
          setVerificationCode('')
          setValidationErrors([])
        }}
      />
    </div>
  )
}

const mapStateToProps = ({ profile }) => ({ phone: profile.phone })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestVerifyNewPhoneCode,
      submitNewPhoneVerificationCode,
      loadProfile
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm)
