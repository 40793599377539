import { Text } from '@oneclickdata/components'
import PropTypes from 'prop-types'
import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native-web'
import { brand } from '../../../utils/CommonStyles'
import { biggerThan, SM_SIZE } from '../../../utils/Responsiveness'
import { CaretBlueImage } from '../Images'

const styles = StyleSheet.create({
  image: { width: 30, height: 77 },
  desktopRightArrow: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    right: -200,
    bottom: -10
  },
  disabled: { opacity: 0.5 },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 68,
    marginHorizontal: 20
  },
  buttonText: {
    textTransform: 'uppercase',
    color: brand.color.highlightBlue,
    size: 'xl',
    letterSpacing: 1.14
  },
  button: {
    borderColor: brand.color.highlightBlue,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderWidth: 1,
    width: 149,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const NextButton = ({ disabled = false, onPress }) => {
  const withImage = biggerThan(SM_SIZE)

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled} style={[withImage ? styles.desktopRightArrow : styles.button, disabled && styles.disabled]}>
      <Text style={styles.buttonText}>NEXT</Text>
      {withImage && <CaretBlueImage style={styles.image} />}
    </TouchableOpacity>
  )
}

NextButton.propTypes = {
  disabled: PropTypes.bool,
  onPress: PropTypes.func.isRequired
}

export default NextButton
