import { MaskService } from 'react-native-masked-text'

const toDisplayPhone = (phone = '') =>
  MaskService.toMask('custom', phone.replace('+', ''), {
    mask: '1 (999) 999-9999'
  })

const toAWSPhone = (masked = '') => {
  const regEx = /[() -]/g

  return MaskService.toMask('custom', masked.replace(regEx, ''), {
    mask: '+19999999999'
  })
}

export { toDisplayPhone, toAWSPhone }
