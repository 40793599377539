import {
  APIOutlinedIcon,
  ArrowLeftIcon,
  Box,
  BulbOutlinedIcon,
  Button,
  ButtonText,
  CalculatorIcon,
  CreditCardOutlinedIcon,
  HStack,
  Icon,
  ManufRequirementsIcon,
  MenuIcon,
  Pressable,
  TeamsIcon,
  UserIcon,
  useShowModal,
  View
} from '@oneclickdata/components'
import { Icon as NBIcon } from '@oneclickdata/occ-components'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import API from '../../api'
import { ROUTES_NAMES } from '../../constants'
import { useDashboardContext } from '../../contexts/Dashboard.context'
import { MOBILE_HEADER_ACTIONS } from '../../contexts/MobileHeaderContext'
import { useAvailability } from '../../customHooks/useAvailability'
import { useLimits } from '../../customHooks/useLimits'
import useMobileHeader from '../../customHooks/useMobileHeader'
import { useTeam } from '../../customHooks/useTeam'
import { unsetLoginUI } from '../../modules/auth'
import { SURVEYS, usePrefetchSurvey } from '../../queryHooks/surveys'
import { BackButton } from '../BackButton'
import Menu, { MenuLink } from '../Menu'
import PurchaseProPlan from '../organisms/PurchaseProPlan'

const {
  profileFull: { route: profileFullRoute },
  integrations: { route: integrationsRoute },
  myTeam: { route: teamRoute },
  calculators: { route: toolsRoute },
  dashboard: { route: dashboardRoute },
  subscription: { route: subscriptionRoute }
} = ROUTES_NAMES

const MobileHeader = ({ onLogout, loggedIn, loginUI, profile }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showUserMenu, setUserMenu] = useState(false)
  const [plan, setPlan] = useState(undefined)
  const [showProPlanModal, setShowProPlanModal] = useState(false)
  const [modalDisplayType, setModalDisplayType] = useState('Integrations')
  const activeSubscription = profile?.subscriptions?.[0]
  const teamsAllowed = activeSubscription?.limits?.allow_team_access
  const integrationsAllowed = activeSubscription?.limits?.allow_integration_access
  const limits = useLimits()
  const availabileTools = limits?.ui_configuration?.availabile_calculators
  const { HTMLContentOfTheContext, mobileHeaderAction, showBackButton, setMobileHeaderAction } = useMobileHeader()
  const team = useTeam()
  const isAdmin = team?.members.find(x => x.id === profile?.userId)?.role === 'admin' || team?.members.find(x => x.id === profile?.userId)?.role === 'owner'
  const { data } = useAvailability()

  const is4P = !!data.code_detail?.allocation && !!data.quick_view?.allocation && !!data.weather?.allocation

  const prefetchSurvey = usePrefetchSurvey()
  const toggleShowUserMenu = useCallback(() => {
    setUserMenu(!showUserMenu)
    prefetchSurvey(SURVEYS.cancellation_reason)
  }, [showUserMenu, prefetchSurvey])

  const { reset } = useDashboardContext()
  const handleProjectsClick = useCallback(() => {
    if (location.pathname === dashboardRoute) {
      location.reload()
    }
    reset()
    toggleShowUserMenu()
  }, [toggleShowUserMenu, reset, location])

  const showModal = useShowModal()

  const handleModalOpen = (displayType) => {
    setModalDisplayType(displayType)
    setShowProPlanModal(true)
  }

  useEffect(() => {
    const loadModal = async () => {
      await showModal({
        isOpen: showProPlanModal,
        children: (
          <PurchaseProPlan
            displayType={modalDisplayType}
            onButtonClick={() => {
              toggleShowUserMenu()
              setShowProPlanModal(false)
            }}
          />
        ),
        onClose: () => setShowProPlanModal(false)
      })
    }
    loadModal()
  }, [showProPlanModal, showModal, toggleShowUserMenu, modalDisplayType])

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ; (async () => {
      try {
        const { userPlan } = await API.getSubscriptionPlan(profile?.userId)
        setPlan(userPlan)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error getting user plan:', error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancel = () => (location.pathname === '/login' ? unsetLoginUI() : navigate('/login'))

  const hideHeader = location === '/signup' || location === '/login' || location === '/forgot-password'

  const handleIntegrationsClick = () => {
    if (integrationsAllowed) {
      toggleShowUserMenu()
      navigate(integrationsRoute, {
        state: {
          from: location.pathname
        }
      })
    } else {
      handleModalOpen('Integrations')
    }
  }

  const handlePlanDetailsClick = () => {
    toggleShowUserMenu()
    navigate(subscriptionRoute, {
      state: {
        from: location.pathname
      }
    })
  }

  const handleTeamsClick = () => {
    if (teamsAllowed) {
      toggleShowUserMenu()
      navigate(teamRoute, {
        state: {
          from: location.pathname
        }
      })
    } else {
      handleModalOpen('MyTeam')
    }
  }

  let handleActionPress = () => null
  let LocalActionIcon = null

  switch (mobileHeaderAction) {
    case MOBILE_HEADER_ACTIONS.userMenu: {
      handleActionPress = toggleShowUserMenu
      LocalActionIcon = MenuIcon
      break
    }
    case MOBILE_HEADER_ACTIONS.back: {
      handleActionPress = () => {
        navigate(-1)
      }
      LocalActionIcon = ArrowLeftIcon
      break
    }
    case MOBILE_HEADER_ACTIONS.backOnce: {
      handleActionPress = () => {
        navigate(-1)
        setMobileHeaderAction(MOBILE_HEADER_ACTIONS.userMenu)
      }
      LocalActionIcon = ArrowLeftIcon
      break
    }
    default: {
      handleActionPress = toggleShowUserMenu
      LocalActionIcon = MenuIcon
    }
  }

  const shouldShowBackButton = showBackButton && location.key !== 'default'

  return (
    <View>
      {shouldShowBackButton && (
        <Box px="$4" alignItems="flex-start">
          <BackButton label="Back" onPress={() => navigate(-1)} />
        </Box>
      )}
      <View
        sx={{
          display: !hideHeader ? 'flex' : 'none',
          p: '$4'
        }}>
        {loggedIn && location !== '/mandatory-profile' && (
          <HStack alignItems="center" gap="$2" minHeight="$10">
            <Pressable
              onPress={handleActionPress}
              sx={{
                ':hover': {
                  backgroundColor: '$backgroundHover',
                  borderRadius: '$sm'
                }
              }}>
              <Icon as={LocalActionIcon} color="$cyan500" size="2xl" />
            </Pressable>
            {HTMLContentOfTheContext}
          </HStack>
        )}
        {!loggedIn && loginUI && (
          <Box pr="$4">
            <Button action="secondary" variant="outline" onPress={handleCancel}>
              <ButtonText>Cancel</ButtonText>
            </Button>
          </Box>
        )}
        <Box
          position="absolute"
          minHeight="100vh"
          backgroundColor="$backgroundSite"
          width="100%"
          zIndex={99}
          top={0}
          left={showUserMenu ? 0 : '-100%'}
          sx={{ transition: 'left 0.2s' }}>
          <Menu showCloseIcon onClose={toggleShowUserMenu} plan={plan} onLogout={onLogout}>
            <Box mt="$2">
              <MenuLink to={dashboardRoute} text="Projects" icon={ManufRequirementsIcon} indicator={<NBIcon name="chevronright" />} onClick={handleProjectsClick} />
              {!!availabileTools?.length && (
                <MenuLink to={toolsRoute} text="Tools" icon={CalculatorIcon} indicator={<NBIcon name="chevronright" size="sm" />} onClick={toggleShowUserMenu} />
              )}
              <MenuLink text="Team" icon={TeamsIcon} indicator={<NBIcon name="chevronright" />} isCta onClick={handleTeamsClick} asButton />
              {is4P && (
                <MenuLink
                  text={isAdmin ? 'Manage Subscription' : 'Plan Details'}
                  icon={CreditCardOutlinedIcon}
                  indicator={<NBIcon name="chevronright" />}
                  style={{
                    marginLeft: '5px'
                  }}
                  to={subscriptionRoute}
                  onClick={handlePlanDetailsClick}
                  asButton
                />
              )}
              {integrationsAllowed && (
                <MenuLink
                  text="Integrations"
                  isCta={!integrationsAllowed}
                  icon={APIOutlinedIcon}
                  indicator={<NBIcon name="chevronright" />}
                  onClick={handleIntegrationsClick}
                  asButton
                />
              )}
              <MenuLink to={profileFullRoute} text="Profile" icon={UserIcon} indicator={<NBIcon name="chevronright" />} onClick={toggleShowUserMenu} />
              <MenuLink
                text="Industry news"
                icon={BulbOutlinedIcon}
                indicator={<NBIcon name="chevronright" />}
                onClick={() => {
                  toggleShowUserMenu()
                  window.open('https://www.oneclickcode.com/blog', '_blank')
                }}
              />
            </Box>
          </Menu>
        </Box>
      </View>
    </View>
  )
}

const mapStateToProps = ({ auth, profile }) => ({
  loggedIn: auth.loggedIn,
  loginUI: auth.loginUI,
  profile
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unsetLoginUI
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader)
