import Cookies from 'js-cookie'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES_NAMES } from '../../../constants'

const RedirectedComponent = ({ firstLoginEver }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = decodeURI(queryParams.get('email'))
  const redirect_to = firstLoginEver ? 'introduce-yourself' : `${location.pathname}${location.search}` || ''

  Cookies.remove('loggedIn', { domain: 'oneclickcode.com' })

  const ignoreRedirectTo = sessionStorage.getItem('ignore_redirect_to')

  if (ignoreRedirectTo) {
    sessionStorage.clear()
  } else {
    sessionStorage.setItem('redirect_to', redirect_to)
  }

  return (
    <Navigate
      replace
      to="/login"
      state={{
        from: location.pathname,
        ...(email &&
          email !== 'null' && {
            email
          })
      }}
    />
  )
}

const requiredAcceptedTos = (profile) => {
  if (!profile) return null
  if (profile.loadingProfile) return null
  const acceptedTosAt = profile.accepted_tos_at
  if (acceptedTosAt) {
    return null
  }

  return true
}

const AuthenticatedRoute = ({ component: Component, loggedIn = false, firstLoginEver = false, ...rest }) => {
  const location = useLocation()
  // eslint-disable-next-line no-shadow
  const { profile } = useSelector(({ profile }) => ({ profile }))
  if (loggedIn) {
    Cookies.set('loggedIn', { loggedIn: true }, { expires: 30, domain: 'oneclickcode.com' })

    if (location.pathname !== ROUTES_NAMES.acceptTos.route && requiredAcceptedTos(profile)) {
      return <Navigate to={ROUTES_NAMES.acceptTos.route} state={{ nextStep: location.pathname } } />
    }
    return <Component {...rest} />
  }

  return <RedirectedComponent firstLoginEver={firstLoginEver} {...rest} />
}

const mapStateToProps = ({ auth }) => ({
  loggedIn: auth.loggedIn,
  firstLoginEver: auth.firstLoginEver
})

export default connect(mapStateToProps)(AuthenticatedRoute)
