import { useEffect, useRef } from 'react'

/**
 * Handles UTM parameters in the URL and local storage.
 *
 * @param {string} newHref - The new URL to process.
 * @returns {string} The processed URL with UTM parameters.
 */
const handleUTMParams = (newHref) => {
  const url = new URL(newHref)
  const params = new URLSearchParams(url.search)
  const utmParams = {}

  // Store UTM parameters from the new URL
  for (const [key, value] of params.entries()) {
    if (key.startsWith('utm_')) {
      utmParams[key] = value
      localStorage.setItem(key, value)
    }
  }

  // If no UTM parameters in the new URL, check local storage
  if (Object.keys(utmParams).length === 0) {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.startsWith('utm_')) {
        params.set(key, localStorage.getItem(key))
      }
    }
    url.search = params.toString()
  }

  return url.toString()
}

/**
 * A custom React hook that manages URL parameters related to environment and UTM tracking.
 *
 * This hook performs the following tasks:
 * 1. Observes changes to the DOM body.
 * 2. Handles UTM parameters in the URL.
 * 3. Adds or updates 'env' or 'occ' parameters based on the current environment.
 * 4. Updates the browser history with the modified URL if necessary.
 *
 * @returns {void}
 */
function useUrlUtmManager() {
  const href = useRef(null)

  useEffect(() => {
    const bodyList = document.querySelector('body')
    const observer = new MutationObserver(() => {
      const newHref = handleUTMParams(window.location.href)
      if (newHref !== href.current) {
        const url = new URL(newHref)
        const searchParams = new URLSearchParams(url.search)
        const currentEnv = process.env.REACT_APP_ENV

        if (['beta', 'dev'].includes(currentEnv)) {
          if (!searchParams.has('env')) {
            searchParams.set('env', currentEnv)
          }
        } else {
          if (!searchParams.has('occ')) {
            searchParams.set('occ', 'p')
          }
        }

        const finalHref =  `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`
        href.current = finalHref

        if (searchParams.toString() !== window.location.search.slice(1)) {
          window.history.replaceState(
            {},
            window.title,
            finalHref
          )
        }
      }
    })
    const config = {
      childList: true,
      subtree: true
    }
    observer.observe(bodyList, config)

    return () => observer.disconnect()
  }, [])
}

export default useUrlUtmManager
