/* eslint-disable global-require */
import { Image as OCCImage } from '@oneclickdata/components'
import { Icon } from '@oneclickdata/occ-components'
import React from 'react'
import { Image } from 'react-native-web'
import { TestIDs } from '../../../utils/testing'
import RawImagesClass from './RawImages'

export const RightCaretImage = props => <Image {...props} testID={TestIDs.images.rightCaret} source={require('../../../images/icons/rightCaret@3x.png')} />
export const FeedbackBlueImage = props => <Image {...props} testID={TestIDs.images.feedbackBlue} source={require('../../../images/icons/feedback_blue@3x.png')} />

export const SkinnyCaretWhiteImage = props => <Image {...props} testID={TestIDs.images.skinnyCaretWhite} source={require('../../../images/icons/skinny_caret_white.png')} />

export const CaretBlueImage = props => <Image {...props} testID={TestIDs.images.caretBlue} source={require('../../../images/icons/skinny_caret_blue.png')} />

export const GreenCheckmarkImage = props => <Image {...props} testID={TestIDs.images.greenCheckmark} source={require('../../../images/icons/icon_green_checkmark.png')} />

export const CloseXYellowImage = props => <Image {...props} testID={TestIDs.images.closeXYellow} source={RawImagesClass.Close} />

export const WhitePlusImage = props => <Image {...props} testID={TestIDs.images.whitePlus} source={require('../../../images/icons/white_plus@3x.png')} />

export const XCloseImage = props => <Image {...props} testID={TestIDs.images.xClose} source={require('../../../images/icons/x_close_button@3x.png')} />

export const BlueCaretDown = props => <Image {...props} testID={TestIDs.images.blueCaretdown} source={require('../../../images/icons/blueDownCaret@3x.png')} />

export const BlueCaretUp = props => <Image {...props} testID={TestIDs.images.blueCaretUp} source={require('../../../images/CaretUpBlue.png')} />

export const GreyArrowDown = props => <Image {...props} testID={TestIDs.images.greyArrowDown} source={require('../../../images/icons/grey_arrow_down@3x.png')} />

export const StatesBlue = props => <Image {...props} testID={TestIDs.images.statesBlue} source={RawImagesClass.StatesBlue} />

export const StatesGray = props => <Image {...props} testID={TestIDs.images.statesGray} source={RawImagesClass.StatesGray} />

export const LocationIcon = props => <Image {...props} testID={TestIDs.images.location} source={RawImagesClass.LocationIcon} />

export const LocationIconGray = props => <Image {...props} testID={TestIDs.images.locationGray} source={RawImagesClass.LocationIconGray} />

export const CheckmarkYellow = props => <Image {...props} testID={TestIDs.images.checkmarkYellow} source={RawImagesClass.CheckmarkYellow} />

export const TooltipInfo = props => <Image {...props} testID={TestIDs.images.tooltipInfo} source={RawImagesClass.TooltipInfo} />

export const LockedWhiteImage = props => <Image {...props} source={RawImagesClass.lockedWhte} />

export const CompanyCamLogoImage = props => <Image {...props} source={RawImagesClass.companyCamLogo} />

export const CompanyCamLogoWhiteImage = props => <Image {...props} source={RawImagesClass.companyCamLogoWhite} />

export const NoIntegrationLogoImage = props => <Image {...props} source={RawImagesClass.noIntegrationImage} />

export const ZapierLogoImage = props => <Image {...props} source={RawImagesClass.zapierLogo} />

export const ClipLinkImage = props => <Image {...props} source={RawImagesClass.link} />

export const LockWhiteImage = props => <Image {...props} source={RawImagesClass.lockWhite} />

export const WhiteCheckmarkImage = props => <Image {...props} source={RawImagesClass.whiteCheckmark} />

export const YellowExclamationImage = props => <Image {...props} source={RawImagesClass.YellowExclamation} />

export const OneClickBannerSmall = props => <OCCImage {...props} source={RawImagesClass.oneClickBannerSmall} />

export const OneClickBannerLarge = props => <Image {...props} source={RawImagesClass.oneClickBannerLarge} />

export const BlueDownCaret = props => <Image {...props} source={RawImagesClass.blueDownCaret} />

export const BluePhone = props => <OCCImage {...props} source={RawImagesClass.bluePhone} />

export const BlueWebsite = props => <OCCImage {...props} source={RawImagesClass.blueWebsite} />
export const DroneLogo = props => <OCCImage {...props} source={RawImagesClass.droneLogo} />

export const PropertyDetailsLogo = props => <OCCImage {...props} source={RawImagesClass.propertyDetailsLogo} />

export const LargeHailZoneIcon = props => <OCCImage {...props} source={RawImagesClass.largeHailStormIcon} />

export const BluePillBeta = props => <OCCImage {...props} source={RawImagesClass.BluePillBeta} />

export const ThreeDotsIcon = props => <OCCImage {...props} source={RawImagesClass.ThreeDots} />

export const RawImages = RawImagesClass

export const IWSCalculationIcon = props => <Icon {...props} name="iws" />

export const VentilationCalculationIcon = props => <Icon {...props} name="vent" />
export const WasteCalculationIcon = props => <Icon {...props} name="waste" />

export const WindIcon = props => <Icon {...props} name="wind" />
